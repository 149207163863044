import React from "react";

import Box from "@amzn/awsui-components-react/polaris/box";

import { IClickableTextProps } from "./definitions";
import styles from "./style.module.scss";

const ClickableText: React.FC<IClickableTextProps> = (
  props: IClickableTextProps
) => {
  return (
    <div className={styles.clickableText}>
      <Box {...props} data-testid="clickable-text">
        <span>{props.text}</span>
      </Box>
    </div>
  );
};

export default ClickableText;
