import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authReducer } from "features/auth";
import { breadcrumbsReducer } from "features/breadcrumbs";
import { infoReducer } from "features/info";
import { notificationsReducer } from "features/notifications";
import { pageHeaderReducer } from "features/page-header";
import { settingsReducer } from "features/settings";
import { timeReducer } from "features/time";
import { detailsReducer } from "features/details";
import { confirmActionReducer } from "features/confirm-action";
/* PLOP_IMPORT_REDUCERS */
import { localApi } from "services/local";
import { mainApi } from "services/main";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    breadcrumbs: breadcrumbsReducer,
    info: infoReducer,
    notifications: notificationsReducer,
    pageHeader: pageHeaderReducer,
    settings: settingsReducer,
    time: timeReducer,
    details: detailsReducer,
    confirmAction: confirmActionReducer,
    /* PLOP_INJECT_REDUCERS */
    [localApi.reducerPath]: localApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Note: One of the core usage principles for Redux is that you should not put
      // non-serializable values in state or actions.
      // See: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      // We turn it off to allow the infoReducer actions to accept non-serializable state value
      // to update the AppLayout tools' inner content with a ReactNode.
      serializableCheck: false,
    })
      .concat(localApi.middleware)
      .concat(mainApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
