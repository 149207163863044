import { useAppDispatch, useAppSelector } from "app/store";

import { BlockedAction } from "./definitions";
import { blockedActionChanged, visibilityChanged } from "./slice";

export const useConfirmAction = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.confirmAction);

  return {
    blockedAction: selector.blockedAction,
    setBlockedAction: (payload: BlockedAction | undefined) =>
      dispatch(blockedActionChanged(payload)),
    visible: selector.visible,
    setVisible: (payload: boolean) => dispatch(visibilityChanged(payload)),
  };
};
