import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
  {
    path: "/",
    lazy: () => import("app/layout"),
    children: [
      {
        index: true,
        lazy: () => import("pages/home"),
      },
      /* PLOP_INJECT_ROUTES */
      {
        path: "/notifications",
        lazy: () => import("pages/notifications"),
      },
      {
        path: "/error-403",
        lazy: () => import("pages/error-403"),
      },
      /* 404 must be the last route */
      {
        path: "*",
        lazy: () => import("pages/error-404"),
      },
    ],
  },
]);
