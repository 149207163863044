import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "components/loader";

import { store } from "./store";
import { router } from "./router";

import "./i18n";

const App: React.FC = () => {
  useTranslation();

  return (
    <Provider store={store}>
      <Suspense fallback={<Loader loadingText="Loading..." />}>
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
};

export const element = <App />;

export default App;
