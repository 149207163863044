import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BlockedAction, IConfirmActionState } from "./definitions";

const initialState: IConfirmActionState = {
  visible: false,
};

export const confirmActionSlice = createSlice({
  name: "confirmAction",
  initialState,
  reducers: {
    blockedActionChanged: (
      state,
      action: PayloadAction<BlockedAction | undefined>
    ) => {
      state.blockedAction = action.payload;
    },
    visibilityChanged: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
});

export const { blockedActionChanged, visibilityChanged } =
  confirmActionSlice.actions;

export default confirmActionSlice.reducer;
