import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "app/store";
import Loader from "components/loader";

import { authenticate } from "./thunk";
import { useOnAuthHandler } from "./hooks";
import { IAuthNProps } from "./definitions";

const ONE_HOUR = 3600;
const TIMER_INTERVAL = ONE_HOUR * 999;

/**
 * Authentication component to render children after authenticating user
 */
const AuthN: React.FC<IAuthNProps> = ({ children }) => {
  const { t } = useTranslation();
  const authenticatingLabel = t("features.auth.authenticating");
  const dispatch = useAppDispatch();
  const { isAuthenticated, expiration } = useAppSelector((state) => state.auth);

  useOnAuthHandler();

  useEffect(() => {
    if (expiration > 0) {
      const interval = setInterval(() => {
        const currentTimestamp = new Date().getTime() / 1000;
        const timeRemaining = expiration - currentTimestamp;
        if (timeRemaining <= ONE_HOUR) {
          dispatch(authenticate(true));
          clearInterval(interval);
        }
      }, TIMER_INTERVAL);
    } else {
      dispatch(authenticate(false));
    }
  }, [dispatch, expiration]);

  if (isAuthenticated) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return <Loader loadingText={authenticatingLabel} />;
  }
};

export default AuthN;
