import React from "react";

import Link from "@amzn/awsui-components-react/polaris/link";

import { useOnFollowHandler } from "./hooks";
import { IDetailsLinkProps } from "./definitions";

const DetailsLink: React.FC<IDetailsLinkProps> = (props: IDetailsLinkProps) => {
  const onFollowHandler = useOnFollowHandler(props);

  return (
    <Link
      variant={props.variant ?? "info"}
      onFollow={onFollowHandler}
      data-testid="details-link"
    >
      {props.text}
    </Link>
  );
};

export default DetailsLink;
