import React from "react";
import { Trans } from "react-i18next";

import Alert from "@amzn/awsui-components-react/polaris/alert";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";

import { useConfirmAction } from "./hooks";

const ConfirmAction: React.FC = () => {
  const { visible, setVisible, blockedAction } = useConfirmAction();

  const confirmAction = () => {
    blockedAction?.();
    setVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={({ detail }) => {
          if (detail.reason !== "overlay") {
            setVisible(false);
          }
        }}
        header="Perform Action"
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setVisible(false)}>
                <Trans>{"features.confirm_action.cancel"}</Trans>
              </Button>

              <Button variant="primary" onClick={confirmAction}>
                <Trans>{"features.confirm_action.confirm"}</Trans>
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <Alert type="warning">
          <Trans>{"features.confirm_action.message"}</Trans>
        </Alert>
      </Modal>
    </>
  );
};

export default ConfirmAction;
