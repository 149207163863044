import React from "react";
import moment from "moment";

import { useAppSelector } from "app/store";
import ClickableText from "components/clickable-text";

import { ITimeProps } from "./definitions";
import { useOnClickHandler } from "./hooks";

const DEFAULT_FORMAT = "MM/DD/YY h:mm a";

const Time: React.FC<ITimeProps> = (props: ITimeProps) => {
  const time = props.isUnix
    ? moment.unix(props.timestamp)
    : moment(props.timestamp);
  const formattedTime = time.format(props.format ?? DEFAULT_FORMAT);
  const relativeTime = time.fromNow();

  const { isRelativeTimeFormat } = useAppSelector((state) => state.time);
  const [label, setLabel] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const onClickHandler = useOnClickHandler();

  React.useEffect(() => {
    if (isRelativeTimeFormat) {
      setLabel(relativeTime);
      setTitle(formattedTime);
    } else {
      setLabel(formattedTime);
      setTitle(relativeTime);
    }
  }, [isRelativeTimeFormat, formattedTime, relativeTime]);

  return (
    <time onClick={onClickHandler} title={title}>
      <ClickableText text={label} variant={props.variant ?? "span"} />
    </time>
  );
};

export default Time;
