import { LinkProps } from "@amzn/awsui-components-react/polaris/link";

import { useAppDispatch, useAppSelector } from "app/store";
import { IDetailsPanelProps, IDetailsLinkProps } from "./definitions";

import { detailsOpened, detailsResized, detailsUpdated } from "./slice";

export const useDetails = () => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.details);

  return {
    details: selector,
    clearDetails: () =>
      dispatch(detailsUpdated({ header: "", details: undefined })),
    updateDetails: (payload: IDetailsPanelProps) =>
      dispatch(detailsUpdated(payload)),
    setOpenDetails: (payload: boolean) => dispatch(detailsOpened(payload)),
    setResizeDetails: (payload: number) => dispatch(detailsResized(payload)),
  };
};

/**
 * Hook for handling side navigation events
 */
export const useOnFollowHandler = (props: IDetailsLinkProps) => {
  const { setOpenDetails } = useDetails();
  const { updateDetails } = useDetails();

  return (event: CustomEvent<LinkProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      if (props.header && props.children) {
        updateDetails({ header: props.header, details: props.children });
      }
      setOpenDetails(true);
    }
  };
};
